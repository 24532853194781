import React from 'react';
import PropTypes from 'prop-types';
import HereHearIntroCard from './hereHearIntroCard';

const HereHearIntroList = () => {
  return (
    <div className="content">
      <HereHearIntroCard 
        title="說心聲"
        text="Here Hear 以全匿名、可變聲的方式讓你毫無顧慮地說出自己的故事。使用敘事治療的心理學理論開發應用，引導你說出自己的故事。當你連續且重複地敘述一件事，你也會發現每次說這個故事帶給你的感受都是不同的。"
        imageUri="/images/screenshot_say.png"
        imagePosition="right"
      />
      <HereHearIntroCard 
        title="聽心聲"
        text="你可以追蹤自己的心率變異性，瞭解自己的交感神經、副交感神經變化情形，以App的事件標記功能，來追蹤你的日常生活壓力，適度休息調整心情。你還能以APP內提供的音樂、冥想、文章，來紓解這些壓力，輕鬆保持心理健康。"
        imageUri="https://via.placeholder.com/375x282"
        imagePosition="left"
      />
      <HereHearIntroCard 
        title="壓力舒緩"
        text={`
在忙碌的生活當中，我們需要適時讓自己的身心靈完全放鬆。
這時正念冥想的引導能讓你的心靈得到舒緩；
而音樂療法有助於消除心理、社會因素所造成的緊張、焦慮、憂鬱等心理狀態，讓心靈重拾平靜狀態。還有更多壓力舒緩的活動：專注力提升、睡眠幫助等功能，等您來探索。
`}
        imageUri="https://via.placeholder.com/375x282"
        imagePosition="right"
      />

      <style jsx>{`
        @media (min-width: 768px) {
        }
      `}</style>
    </div>
  );
};

HereHearIntroList.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  imageUri: PropTypes.string,
  imageAlt: PropTypes.string,
  imagePosition: PropTypes.string,
};

HereHearIntroList.defaultProps = {
  title: '',
  text: '',
  imageUri: '',
  imageAlt: '',
  imagePosition: 'right',
};

export default HereHearIntroList;
