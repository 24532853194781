import React from 'react';
import PropTypes from 'prop-types';
import ShoppingButton from './shoppingButton';
import colors from '../config/color';

const HereHearIntroCard = props => {
  const { title, text, imageUri, imageAlt, imagePosition } = props;

  return (
    <div className="content">
      <div className="text-content">
        <h2>{title}</h2>
        <p>{text}</p>
        <div className="shopping">
          <ShoppingButton text="加入購物車" />
        </div>
      </div>
      <img className="img-content" alt={imageAlt} src={imageUri} />

      <style jsx>{`
        .content {
          margin: 20px 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .text-content {
          display: flex;
          flex-direction: column;
          text-align: left;
        }

        h2 {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Medium;
          font-size: 36px;
          line-height: 60px;
          text-align: center;
          margin-bottom: 10px;
        }

        p {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 20px;
        }

        .shopping {
          text-align: left;
        }

        .img-content {
          width: 100%;
          margin: 80px 0;
        }

        @media (min-width: 768px) {
          .content {
            margin-top: 0;
            flex-direction: ${imagePosition === 'right'
              ? 'row'
              : 'row-reverse'};
          }

          .text-content {
            padding: ${imagePosition === 'right' ? '0 20px 0 0' : '0 0 0 40px'};
            display: flex;
            justify-content: center;
            width: 42%;
          }

          .img-content {
            width: 54%;
            /* object-fit: cover; */
          }

          h2 {
            font-size: 48px;
          }

          p {
            font-size: 18px;
            line-height: 27px;
            margin-top: 24px;
          }

          .shopping {
            padding-top: 24px;
          }
        }
      `}</style>
    </div>
  );
};

HereHearIntroCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  imageUri: PropTypes.string,
  imageAlt: PropTypes.string,
  imagePosition: PropTypes.string,
};

HereHearIntroCard.defaultProps = {
  title: '',
  text: '',
  imageUri: '',
  imageAlt: '',
  imagePosition: 'right',
};

export default HereHearIntroCard;
